import { Canvas, extend } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'
import { FC, forwardRef, Ref, Suspense, useEffect, useState } from 'react'
import { BackSide, type Texture } from 'three'

extend({ OrbitControls })

const Dome: FC<{ texture: Texture | undefined }> = ({ texture }) => {
  const [flippedTexture, setFlippedTexture] = useState<Texture | undefined>(texture)

  useEffect(() => {
    if (texture) {
      const newTexture = texture.clone()
      newTexture.repeat.x = -1
      newTexture.offset.x = 1
      setFlippedTexture(newTexture)
    }
  }, [texture])

  return (
    <Suspense fallback={null}>
      <mesh>
        <sphereGeometry args={[500, 60, 40, Math.PI]} />
        <meshBasicMaterial map={flippedTexture} side={BackSide} />
      </mesh>
    </Suspense>
  )
}

type ViewerProps = {
  texture: Texture | undefined
  ref: Ref<HTMLCanvasElement> | undefined
}

export const Viewer: FC<ViewerProps> = forwardRef(function Viewer(props, ref) {
  return (
    <Canvas
      ref={ref}
      gl={{ preserveDrawingBuffer: true }}
      camera={{
        fov: 100,
        near: 0.1,
        far: 1000,
        position: [0, 0, 5],
      }}
    >
      <OrbitControls enableZoom={false} enableDamping={false} panSpeed={0.7} />
      <Dome texture={props.texture} />
    </Canvas>
  )
})
