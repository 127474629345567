import { ChangeEventHandler, useEffect, useRef, useState } from 'react'
import { TextureLoader, Texture } from 'three'
import { Viewer } from './components/Viewer'
import {
  FolderOpenIcon,
  CameraIcon,
  ArrowDownTrayIcon,
  ArrowsPointingOutIcon,
} from '@heroicons/react/20/solid'

const App = () => {
  const textureLoader = new TextureLoader()
  const [texture, setTexture] = useState<Texture>(textureLoader.load('/sample.webp'))

  const inputRef = useRef<HTMLInputElement>(null)
  const openImageFile = () => inputRef.current?.click()
  const handleFiles: ChangeEventHandler<HTMLInputElement> = (event) => {
    const file = event.target.files?.[0]
    if (file) setTexture(textureLoader.load(URL.createObjectURL(file)))
  }

  const [showDropIcon, setShowDropIcon] = useState(false)

  const handleDrag = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    handleDrag(event)
    setShowDropIcon(false)
    const file = event.dataTransfer.files?.[0]
    if (file) setTexture(textureLoader.load(URL.createObjectURL(file)))
  }

  const isClipboardEvent = (event: Event): event is ClipboardEvent => {
    return 'clipboardData' in event
  }

  const handlePaste = (event: Event) => {
    if (!isClipboardEvent(event)) return
    if (!event.clipboardData) return
    const file = Array.from(event.clipboardData.items)
      .find((item) => item.kind === 'file')
      ?.getAsFile()
    if (file) setTexture(textureLoader.load(URL.createObjectURL(file)))
  }

  useEffect(() => {
    window.addEventListener('paste', handlePaste)
    return () => window.removeEventListener('paste', handlePaste)
  }, [texture])

  const ref = useRef<HTMLCanvasElement>(null)
  const takeScreenShot = () => {
    if (ref.current) {
      const link = document.createElement('a')
      const filename = `screenshot_${Math.floor(Math.random() * (999 - 100 + 1) + 100)}.png`
      link.download = filename
      link.href = ref.current.toDataURL('image/png').replace('image/png', 'image/octet-stream')
      link.click()
      link.remove()
    }
  }

  const [isFullscreen, setIsFullscreen] = useState(false)
  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement)
    }

    document.addEventListener('fullscreenchange', handleFullscreenChange)

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange)
    }
  }, [])
  const toggleFullScreen = () => {
    if (document.fullscreenElement) {
      setIsFullscreen(false)
      document.exitFullscreen()
    } else {
      if (ref.current) {
        setIsFullscreen(true)
        ref.current.requestFullscreen()
      }
    }
  }

  return (
    <div
      className='container mx-auto px-3 mt-4 font-mono'
      onDragEnter={(e) => {
        handleDrag(e)
        setShowDropIcon(true)
      }}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      onDragLeave={(e) => {
        handleDrag(e)
        setShowDropIcon(false)
      }}
    >
      <h1 className='text-2xl mb-1 font-bold'>Panorama Viewer</h1>
      <div
        className={`aspect-video rounded overflow-hidden relative ${
          isFullscreen ? 'fixed w-screen h-screen' : 'w-full'
        }`}
      >
        <Viewer ref={ref} texture={texture} />
        <div className='absolute bottom-3 right-3'>
          <div className='flex gap-2'>
            <input
              ref={inputRef}
              type='file'
              accept='image/*'
              className='hidden'
              onChange={handleFiles}
            />
            <button
              className='w-10 h-10 hover:bg-white/20 rounded-sm grid place-items-center transition-all duration-200 ease-in-out'
              onClick={() => openImageFile()}
            >
              <FolderOpenIcon className='w-6 h-6 text-white' />
            </button>
            <button
              className='w-10 h-10 hover:bg-white/20 rounded-sm grid place-items-center transition-all duration-200 ease-in-out'
              onClick={() => takeScreenShot()}
            >
              <CameraIcon className='w-6 h-6 text-white' />
            </button>
            <button
              className='w-10 h-10 hover:bg-white/20 rounded-sm grid place-items-center transition-all duration-200 ease-in-out'
              onClick={() => toggleFullScreen()}
            >
              <ArrowsPointingOutIcon className='w-6 h-6 text-white' />
            </button>
          </div>
        </div>
      </div>
      <div>
        <p className='text-sm text-right mt-1'>
          ©2023{' '}
          <a
            className='underline'
            href='https://www.andeco.co.jp/'
            target='_blank'
            rel='noreferrer'
          >
            Andeco.inc
          </a>
        </p>
      </div>
      <div
        className={`fixed top-0 left-0 w-screen h-screen grid place-items-center bg-black/50 pointer-events-none transition-all duration-200 ease-in-out ${
          showDropIcon ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <div className='flex flex-col items-center'>
          <ArrowDownTrayIcon className='w-16 h-16 text-white' />
          <span className='text-white mt-4 text-lg'>Drop to open.</span>
        </div>
      </div>
    </div>
  )
}

export default App
